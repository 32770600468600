.text-animate {
    display: inline-block;
    opacity: 0;
    animation: bounceIn;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    min-width: 10px;
}

.text-animate-hover {
    min-width: 10px;
    display: inline-block;
    animation-fill-mode: both;

    &:hover {
        animation: rubberBand 1s;
        // color: #5250b6 !important;
    }
}

@for $i from 1 through 37 {
    .text-animate._#{$i} {
        animation-delay: #{$i / 20}s;
    }
}