.contact-page{
    .text-zone {

        h1 {
        color: #22223B !important;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        }

        .contact-form {
        width: 100%;
        margin-top: 20px;

                ul {
                padding: 0;
                margin: 0;

                    li {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    margin-bottom: 10px;
                    overflow: hidden;
                    display: block;
                    position: relative;
                    opacity: 0;
                    animation: fadeIn 2s 2s;
                    animation-fill-mode: forwards;
                    clear: both;
                    }

                    li.half {
                    width: 49%;
                    margin-left: 2%;
                    float: left;
                    clear: none;

                    &:first-child {
                    margin-left: 0;
                    }
                }
            }

            input[type = 'text'], 
            input[type = 'email'] {
            width: 100%;
            border: 0;
            background-color: #f2f2f2;
            height: 50px;
            font-size: 16px;
            color: #4d4d4e;
            padding: 0 20px;
            box-sizing: border-box;
            }

            textarea {
            width: 100%;
            border: 0;
            background-color: #f2f2f2;
            height: 50px;
            font-size: 16px;
            color: #4d4d4e;
            padding: 20px;
            min-height: 150px;
            box-sizing: border-box;
            }

                .flat-button {
                color: #F2E9E4;
                background-color: #22223B;
                font-family: 'Coolvetica';
                font-size: 16px;
                //border-radius: 25px;
                padding: 15px;
                text-decoration: none;
                letter-spacing: 4px;
                animation: fadeIn 1s 1.8s backwards;
                float: right;

            
                    &:hover{
                    background: #F2E9E4;
                    color: #22223B;
                    }
                }
        }

    }
}

            