.projects-page {

    h1 {
        color: #22223B;
        font-size: 96px !important;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        position: sticky;
    }


    .project-one{
        
        width: 100%;

        h2{
            color: #4A4E69;
            font-size: 36px;
        }

        p {
            width: 45%;
            height: 90%;
            overflow-x: auto;
            font-size: 24px;
        }

        .project-one-info{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                width: 25%;
            }
        }
    }

    .project-two {
        width: 100%;

        h2 {
            color: #4A4E69;
            font-size: 36px;
        }

        p {
            width: 45%;
            height: 90%;
            overflow-x: auto;
            font-size: 24px;
        }

        .project-two-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                padding-right: 25px;
                width: 35%;
            }
        }
        
    }

    .project-three {

        width: 100%;

        h2{
            color: #4A4E69;
            font-size: 36px;
        }

        p {
            width: 45%;
            height: 90%;
            overflow-x: auto;
            font-size: 24px;
            padding-right: 50px;
        }

        .project-three-info {
            display: flex;
            align-items: center;
            justify-content: flex-start; 
            padding-bottom: 50px;  
        }
    }

    
    .text-area {
        position: absolute;
        left: 15%;
        top: 50%;
        transform:  translateY(-50%);
        width: 50%;
        min-width: 75%;
        max-height: 90%;
        overflow-y: scroll;
        display: flex;
        align-items: flex-start;
    }


}